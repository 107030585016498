import * as React from "react";
import { SDKResponseError } from "@badger/gateway-client-sdk";
import { useAuth } from "../shared/useAuth";
import {
  Well,
  WellHeading
} from "@fennec/ui-common-cra/dist/components/lib/Well";
import styles from "./ErrorMessage.module.css";
import { useContent } from "@fennec/ui-glossary";

import { authBaseUrl } from "../config";

export function ErrorMessage(props: { error: any }) {
  const { signOut } = useAuth();
  const is = useContent("805D1BBF-BFD7-48F2-B377-21C72E0013AF");
  const { error } = props;
  if (error?.status === 401) {
    signOut(authBaseUrl);
    return null;
  }
  const fallbackMessage = is("Something went wrong.");

  const message =
    error instanceof SDKResponseError
      ? error?.error?.status === 500
        ? fallbackMessage
        : error?.error?.status === 403
        ? is("no_permission", "You don't have permission to view this content.")
        : error?.error?.message ?? fallbackMessage
      : error?.message ?? fallbackMessage;
  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <Well>
          <WellHeading>{message}</WellHeading>
        </Well>
      </div>
    </div>
  );
}
