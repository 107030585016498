import * as React from "react";
import { Button } from "@fennec/ui-common-cra/dist/components/lib/Button";
import styles from "./DefaultErrorBoundary.module.css";

import { defaultLoginPath } from "../config";
import { useContent } from "@fennec/ui-glossary";

export class DefaultErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    return this.state.hasError ? <Error /> : this.props.children;
  }
}

const Error = () => {
  const is = useContent("F6613DCF-A7DC-4B5E-A04E-F2BC80D4B2A7");
  
  return (
    <div className={styles.root}>
      <h1 className={styles.heading}>
        {is("error", "Something went wrong :(")}
      </h1>
      <div className={styles.subHeading}>
        Please reload the page, or sign out.
      </div>
      <Button
        type="button"
        onClick={() => {
          // Can't use useAuth, since it's below in the tree
          window.localStorage.clear();
          window.location.href = defaultLoginPath;
        }}
      >
        {is("Sign Out")}
      </Button>
    </div>
  )
}
