// 3rd party modules
import debounce from "lodash/debounce";
import * as React from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import {
  BrowserRouter,
  Navigate,
  useMatch,
  generatePath,
  Route,
  Routes,
  useLocation,
  RouteProps
} from "react-router-dom";

// fennec modules
import { SDK } from "@badger/gateway-client-sdk";
import { DocumentTitle } from "@fennec/ui-common-cra/dist/components/lib/DocumentTitle";
import { FullScreenLoading } from "@fennec/ui-common-cra/dist/components/FullScreenLoading";
import { IconAccount } from "@fennec/ui-common-cra/dist/components/IconAccount";
import { IconInfo } from "@fennec/ui-common-cra/dist/components/IconInfo";
import { IconMember } from "@fennec/ui-common-cra/dist/components/IconMember";
import { IconSettings } from "@fennec/ui-common-cra/dist/components/IconSettings";
import { PageOneHeader } from "@fennec/ui-common-cra/dist/components/PageOneHeader";
import {
  NavTabList,
  NavTab
} from "@fennec/ui-common-cra/dist/components/NavTabs";

// internal modules
import * as auth from "./shared/auth";
import { AuthContext } from "./shared/AuthContext";
import { BreadcrumbOrganization } from "./components/BreadcrumbOrganization";
import { BreadcrumbProject } from "./components/BreadcrumbProject";
import { DefaultErrorBoundary } from "./components/DefaultErrorBoundary";
import { ErrorMessage } from "./components/ErrorMessage";
import { Layout } from "./components/Layout";
import { RiskAssessmentContextProvider } from "./shared/RiskAssessmentContext";
import { SDKContext } from "./shared/SDKContext";
import { SidebarContextProvider } from "./components/Sidebar";
import { UserContext } from "./shared/UserContext";
import {
  apiBaseUrl,
  appRouterBasename,
  Fennec,
  deploymentEnv,
  glossaryBaseUrl,
  authClientId
} from "./config";
import { defaultLoginPath } from "./config";
import { formatDocumentTitle } from "./shared/formatDocumentTitle";
import { redirectLocationKey } from "./shared/redirectLocationKey";
import { useSDK } from "./shared/useSDK";
import { useUser } from "./shared/useUser";
import { useContent, GlossaryContextProvider } from "@fennec/ui-glossary";

// Lazy loads
const About = React.lazy(() => import("./screens/About/About"));
const Account = React.lazy(() => import("./screens/Account/Account"));
const Preferences = React.lazy(
  () => import("./screens/Preferences/Preferences")
);
const AddMembers = React.lazy(
  () => import("./screens/OrganizationMemberDashboard/MemberAdd")
);
const LoggedOut = React.lazy(() => import("./screens/LoggedOut/LoggedOut"));
const Login = React.lazy(() => import("./screens/Login/Login"));
const LoginTest = React.lazy(() => import("./screens/LoginTest/LoginTest"));
const NotFound = React.lazy(() => import("./screens/404/404"));
const Notifications = React.lazy(
  () => import("./screens/Notifications/Notifications")
);
const OAuth = React.lazy(() => import("./screens/OAuth/OAuth"));
const Organization = React.lazy(
  () => import("./screens/Organization/Organization")
);
const OrganizationCreate = React.lazy(
  () => import("./screens/OrganizationCreate/OrganizationCreate")
);
const OrganizationMembers = React.lazy(
  () => import("./screens/OrganizationMembers/OrganizationMembers")
);
const OrganizationMembersAddToProjects = React.lazy(
  () =>
    import(
      "./screens/OrganizationMemberAddToProjects/OrganizationMemberAddToProjects"
    )
);
const Project = React.lazy(() => import("./screens/Project/Project"));
const ProjectCreate = React.lazy(
  () => import("./screens/ProjectCreate/ProjectCreate")
);
const OrganizationMemberDashboard = React.lazy(
  () =>
    import("./screens/OrganizationMemberDashboard/OrganizationMemberDashboard")
);
const ProjectOrganization = React.lazy(
  () => import("./screens/ProjectOrganization/ProjectOrganization")
);
const Projects = React.lazy(() => import("./screens/Projects/Projects"));
const ProjectsRequestAccess = React.lazy(
  () => import("./screens/ProjectsRequestAccess/ProjectsRequestAccess")
);

const ProjectMemberDashboard = React.lazy(
  () => import("./screens/ProjectMemberDashboard/ProjectMemberDashboard")
);

const Welcome = React.lazy(() => import("./screens/Welcome/Welcome"));

type RouteConfig = {
  path: string;
  title: string;
  // comp: React.LazyExoticComponent<() => JSX.Element>;
  comp: any;
  Layout: (props: any) => JSX.Element;
  layoutProps?: any;
  // Route?: (props: RouteProps) => JSX.Element;
  Route?: any;
};

export const routes: Array<RouteConfig> = [
  {
    path: "/login",
    title: "Login",
    comp: <Login />,
    Layout: PublicLayout,
    Route: RedirectIfAuthenticatedRoute
  },
  {
    path: "/login-test",
    title: "Login",
    comp: <LoginTest />,
    Layout: PublicLayout,
    Route: RedirectIfAuthenticatedRoute
  },
  {
    path: "/logged-out",
    title: "Logged Out",
    comp: <LoggedOut />,
    Layout: PublicLayout,
    Route: PublicRoute
  },
  {
    path: "/oauth",
    title: "Auth",
    comp: <OAuth />,
    Layout: PublicLayout,
    Route: PublicRoute
  },
  ...createTabRoutes({
    title: "Account",
    tabs: [
      {
        path: "/account",
        text: "My Profile",
        title: "My Profile",
        icon: IconAccount,
        comp: <Account />
      },
      {
        path: "/preferences",
        text: "Preferences",
        title: "Preferences",
        icon: IconSettings,
        comp: <Preferences />
      },
      {
        path: "/about",
        text: "About",
        title: "About",
        icon: IconInfo,
        comp: <About />
      }
    ]
  }),
  {
    path: "/organizations",
    title: "Organization",
    comp: <ProjectOrganization />,
    Layout,
    Route: PrivateRoute
  },
  // {
  //   path: "/users",
  //   title: "Users",
  //   comp: <ProjectUsers />,
  //   Layout,
  //   Route: PrivateRoute
  // },
  {
    path: "/members/:uuid/new",
    title: "Add Members",
    comp: <AddMembers />,
    Layout,
    Route: PrivateRoute
  },
  {
    path: "/organizations/:uuid/members",
    title: "Organization Members",
    comp: <OrganizationMemberDashboard />,
    Layout,
    Route: PrivateRoute
  },
  {
    path: "/organizations/:uuid/members/:userUuid",
    title: "Add to Projects",
    comp: <OrganizationMembersAddToProjects />,
    Layout,
    Route: PrivateRoute
  },
  {
    path: "/organizations/new",
    title: "New Organization",
    comp: <OrganizationCreate />,
    Layout,
    Route: PrivateRoute
  },
  ...createTabRoutes({
    title: "Intervention",
    tabs: [
      {
        path: "/organizations/:uuid",
        text: "Settings",
        title: "Organization Settings",
        icon: IconSettings,
        comp: <Organization />
      },
      {
        path: "/organizations/:uuid/members",
        text: "Members",
        title: "Organization Members",
        icon: IconMember,
        comp: <OrganizationMembers />
      }
    ],
    layoutProps: {
      header: (
        <PageOneHeader>
          <BreadcrumbOrganization />
        </PageOneHeader>
      )
    }
  }),
  {
    path: "/projects",
    title: "Projects",
    comp: <Projects />,
    Layout,
    Route: PrivateRoute
  },
  {
    path: "/projects-request-access",
    title: "Request Access",
    comp: <ProjectsRequestAccess />,
    Layout,
    Route: PrivateRoute
  },
  {
    path: "/projects/new",
    title: "New Project",
    comp: <ProjectCreate />,
    Layout,
    Route: PrivateRoute
  },
  {
    path: "/welcome",
    title: "Welcome",
    comp: <Welcome />,
    Layout,
    Route: PrivateRoute
  },
  ...[
    {
      path: "/projects/:uuid",
      title: "Project",
      comp: <Project />,
      Layout,
      Route: PrivateRoute
    },
    {
      path: "/projects/:uuid/members",
      title: "Organization Members",
      comp: <ProjectMemberDashboard />,
      Layout,
      Route: PrivateRoute
    }
  ].map((x) => ({
    ...x,
    layoutProps: {
      header: (
        <PageOneHeader>
          <BreadcrumbProject />
        </PageOneHeader>
      )
    }
  })),
  {
    path: "/notifications",
    title: "Notifications",
    comp: <Notifications />,
    Layout,
    Route: PrivateRoute
  }
];

type Tab = {
  path: string;
  text: string;
  icon?: any;
  title?: string;
  // comp: React.LazyExoticComponent<() => JSX.Element>;
  comp: any;
};

function createTabRoutes(input: {
  title: string;
  tabs: Array<Tab>;
  layoutProps?: any;
  propagateQueryParams?: boolean;
  filterTabListPred?: (user: any) => (x: Tab, i: number) => boolean;
}): Array<RouteConfig> {
  const {
    tabs,
    layoutProps = {},
    propagateQueryParams = false,
    filterTabListPred
  } = input;
  return tabs.map((x) => ({
    path: x.path,
    title: x.title ?? input.title,
    comp: x.comp,
    Layout: (props: { children: any }) => {
      const is = useContent("FD947CA1-6B2B-4B57-A305-0615B362F2B1");
      const routeMatch = useMatch({
        path: x.path,
        caseSensitive: true,
        end: true
      });
      const { user } = useUser();
      let filtereredTabs = tabs;
      if (filterTabListPred) {
        filtereredTabs = tabs.filter(filterTabListPred(user));
      }
      return (
        <Layout
          tabs={
            <NavTabList>
              {filtereredTabs.map((x, i) => {
                let to = generatePath(x.path, routeMatch?.params ?? {});
                if (propagateQueryParams) {
                  to += window.location.search;
                }
                return (
                  <NavTab key={i} to={to}>
                    {x.icon == null ? null : <x.icon />}
                    {is(x.text)}
                  </NavTab>
                );
              })}
            </NavTabList>
          }
          {...layoutProps}>
          {props.children}
        </Layout>
      );
    }
  }));
}

function PublicLayout(props: { children: any }) {
  return props.children;
}

const queryClient = new QueryClient();

export default function App() {
  const [token, setToken] = React.useState(auth.getToken());
  const sdk = new SDK({
    baseUrl: apiBaseUrl,
    queryStringOpts: { arrayFormat: "repeat" }
  });
  if (token != null) {
    sdk.__setHeaders((x: any) => ({ ...x, Authorization: `Bearer ${token}` }));
  }
  const signIn = (
    creds: { accessToken: string; idToken: string },
    cb?: () => void
  ) => {
    const token = creds.accessToken;
    auth.setToken(token);
    auth.setIdToken(creds.idToken);
    auth.setLoginClientId(authClientId); // save a record that this app was used for login
    sdk.__setHeaders((x: any) => ({ ...x, Authorization: `Bearer ${token}` }));
    cb?.();
    setToken(token);
  };

  const signOut = debounce(
    (authBaseUrl: string) => {
      // get values from localstorage before clearing
      const idToken = auth.getIdToken();
      const clientId = auth.getLoginClientId(); // get the clientId that was used for login
      // clear localstorage
      window.localStorage.clear();
      // clear query client cache
      queryClient.clear();
      // go to logout URL
      window.location.href = Fennec.LOGOUT_URL({
        authBaseUrl,
        origin: window.location.origin,
        idToken,
        clientId
      });
    },
    1000,
    { leading: true }
  );

  return (
    <DefaultErrorBoundary>
      <AuthContext.Provider value={{ token, signIn, signOut }}>
        <SDKContext.Provider value={{ sdk }}>
          <QueryClientProvider client={queryClient}>
            <GlossaryContextProvider
              params={{
                deploymentEnv: deploymentEnv || "STAGING",
                glossaryBaseUrl:
                  glossaryBaseUrl ||
                  "https://glossary.staging.fennecplatform.com",
                glossaryAppName: "BADGER",
                data: undefined
              }}>
              <SidebarContextProvider>
                <RiskAssessmentContextProvider>
                  <BrowserRouter basename={appRouterBasename}>
                    <React.Suspense fallback={<FullScreenLoading />}>
                      <Routes>
                        <Route path="/" element={<Navigate to="/welcome" />} />
                        {routes.map((x, i) => {
                          const RouteComp = x.Route ?? PrivateRoute;
                          return (
                            <Route
                              key={x.path}
                              path={x.path}
                              element={
                                <RouteComp>
                                  <DocumentTitle>
                                    {formatDocumentTitle(x.title)}
                                  </DocumentTitle>
                                  <x.Layout {...x.layoutProps}>
                                    <React.Suspense
                                      fallback={<FullScreenLoading />}>
                                      {x.comp}
                                    </React.Suspense>
                                  </x.Layout>
                                </RouteComp>
                              }
                            />
                          );
                        })}
                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    </React.Suspense>
                  </BrowserRouter>
                </RiskAssessmentContextProvider>
              </SidebarContextProvider>
            </GlossaryContextProvider>
          </QueryClientProvider>
        </SDKContext.Provider>
      </AuthContext.Provider>
    </DefaultErrorBoundary>
  );
}

// If already authenticated, redirect to redirect location
function RedirectIfAuthenticatedRoute(props: RouteProps) {
  const { children } = props;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectLocation = searchParams.get(redirectLocationKey) || "/";

  return !auth.isAuthenticated() ? (
    <>{children}</>
  ) : (
    <Navigate to={redirectLocation} />
  );
}

function PublicRoute(props: { children: React.ReactNode }) {
  return props.children;
}

function PrivateRoute(props: RouteProps) {
  const { children } = props;
  const token = auth.getToken();
  const location = useLocation();

  if (token == null) {
    return (
      <Navigate
        to={{
          pathname: defaultLoginPath,
          search: new URLSearchParams({
            [redirectLocationKey]: location.pathname + location.search
          }).toString()
        }}
      />
    );
  } else {
    return (
      <UserProvider>
        {(user: any) => (
          <UserContext.Provider value={{ user }}>
            {children}
          </UserContext.Provider>
        )}
      </UserProvider>
    );
  }
}

function UserProvider(props: { children: any }) {
  const { sdk } = useSDK();
  const result = useQuery("me", () => sdk.getMeUser(), { retry: false });

  if (result.status === "loading") {
    return <FullScreenLoading />;
  }

  if (result.status === "error" || result.data === undefined) {
    return <ErrorMessage error={result.error} />;
  }
  const user = result.data.data;

  return props.children(user);
}
