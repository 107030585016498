import React from 'react'

export type RiskAssessmentSettings = {
  collapsedState: Set<string>;
  setCollapsedState: React.Dispatch<React.SetStateAction<Set<string>>>;
  selectedHazardUuid: string;
  onSelectHazardUuid: (hazardUuid: string, hazardousEventUuid: string) => void;  
};

export const RiskAssessmentContext = React.createContext<RiskAssessmentSettings>({
  // @ts-ignore
  undefined
});

export function useRiskAssessmentContext() {
  return React.useContext(RiskAssessmentContext);
}

export function RiskAssessmentContextProvider(props: { children: React.ReactNode }) {
  const { children } = props;
  const [collapsedState, setCollapsedState] = React.useState<Set<string>>(new Set())
  const [selectedHazardUuid, setSelectedHazardUuid] = React.useState<string>('')

  const handleSelectHazardUuid = (hazardUuid: string, hazardousEventUuid: string) => {
    const newCollapsedState = new Set(collapsedState);
    if (newCollapsedState.has(hazardousEventUuid)) {
      newCollapsedState.delete(hazardousEventUuid)
    }
    setCollapsedState(newCollapsedState)
    setSelectedHazardUuid(hazardUuid)
  }

  return (
    <RiskAssessmentContext.Provider
      value={{
        collapsedState,
        setCollapsedState,
        onSelectHazardUuid: handleSelectHazardUuid,
        selectedHazardUuid,
       }}
    >
      {children}
    </RiskAssessmentContext.Provider>
  )
}