import * as React from "react";
import { MdOutlineCheckBox } from "react-icons/md";
import Logo from "../assets/logo.png";
import { View } from "@fennec/ui-common-cra/dist/components/lib/View";
import styles from "./Header.module.css";
import { authBaseUrl } from "../config";
import { Pressable } from "@fennec/ui-common-cra/dist/components/lib/Pressable";
import { Badge } from "@fennec/ui-common-cra/dist/components/lib/Badge";
import { useSDK } from "../shared/useSDK";
import { useQuery } from "react-query";
import { useUser } from "../shared/useUser";
import { useContent } from "@fennec/ui-glossary";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "./ErrorMessage";
import { ToolNavigation } from "@fennec/ui-common-cra/dist/components/tool-navigation/ToolNavigation";
import { useAuth } from "../shared/useAuth";

export function Header() {
  const { sdk } = useSDK();
  const { signOut } = useAuth();
  const headerResult = useQuery(["header"], () =>
    sdk.getPlatformNavigation(null, {
      tool: "BADGER"
    })
  );

  if (headerResult.status === "error") {
    return <ErrorMessage error={headerResult.error} />;
  }

  const headerData = headerResult.data?.data;
  const loading = headerResult.status === "loading" || headerData === undefined;
  return (
    <ToolNavigation
      isLoading={loading}
      logo={Logo}
      toolName={"Badger"}
      signOutCallback={() => signOut(authBaseUrl)}
      customSection={<HeaderNotifications />}
      user={headerData?.user ?? { firstName: "", lastName: "" }}
      docBaseUrl={headerData?.docBaseUrl ?? ""}
      supportBaseUrl={headerData?.supportBaseUrl ?? ""}
      navigation={headerData?.navigation ?? []}
      otherLinks={headerData?.otherLinks ?? []}
    />
  );
}

function HeaderNotifications() {
  const is = useContent("3E74299D-99AF-4055-B635-8C49D6F7C429");
  const { sdk } = useSDK();
  const { user } = useUser();
  const navigate = useNavigate();
  const result = useQuery(
    ["notifications", user?.uuid],
    () => sdk.getNotificationList(null, { userUuid: user.uuid, order: "desc" }),
    { enabled: !!user.uuid }
  );

  if (!user.uuid) return null;

  if (result.isLoading) {
    return null;
  }
  if (result.isError) {
    console.error(is("Error fetching notifications: "), result.error);
    return null;
  }

  const notifications = result.data?.data?.results || [];
  const newNotifications = notifications.filter((x) => !x.read);

  return (
    <View style={styles.sideMenuButtonContainer}>
      <Pressable
        onClick={() => navigate("/notifications")}
        title={is("Notifications")}>
        <View style={styles.notificationsButtonContainer}>
          <MdOutlineCheckBox size={20} />
          <View style={styles.badgeContainer}>
            {!!newNotifications.length && (
              <Badge
                size="sm"
                color={"var(--pltNeutral100)"}
                backgroundColor="#428fdc">
                {`${newNotifications.length}`}
              </Badge>
            )}
          </View>
        </View>
      </Pressable>
    </View>
  );
}
