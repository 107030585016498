import * as React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { DefaultTheme } from "@fennec/ui-common-cra/dist/components/themes/DefaultTheme";
import { appRouterBasename } from "./config";

// Logic to make sure that the app opens in the same URL as basename.
if (!window.location.pathname.startsWith(appRouterBasename)) {
  window.history.replaceState(
    "",
    "",
    appRouterBasename + window.location.pathname
  );
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <DefaultTheme>
      <App />
    </DefaultTheme>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
