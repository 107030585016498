export const apiBaseUrl = process.env.REACT_APP_BASE_API_URL as string;
export const appRouterBasename = process.env.REACT_APP_ROUTER_BASENAME ?? "";

export const appName = "Badger";
export const titleSuffix = ` | ${appName}`;

export const authBaseUrl = process.env.REACT_APP_AUTH_BASE_URL ?? "";

export const frontendBaseUri = (origin: string) =>
  `${origin}${appRouterBasename}`;

export const authClientId = process.env.REACT_APP_AUTH_CLIENT_ID as string;

export const deploymentEnv = process.env.REACT_APP_DEPLOYMENT_ENV;

export const harmadilloBaseUrl = process.env
  .REACT_APP_BASE_FE_URL_HARMADILLO as string;
export const quokkaBaseUrl = process.env.REACT_APP_BASE_FE_URL_QUOKKA as string;
export const ardvarcBaseUrl = process.env
  .REACT_APP_BASE_FE_URL_ARDVARC as string;
export const mongooseBaseUrl = process.env
  .REACT_APP_BASE_FE_URL_MONGOOSE as string;
export const pikaBaseUrl = process.env.REACT_APP_BASE_FE_URL_PIKA as string;
export const ardvarcxBaseUrl = process.env
  .REACT_APP_BASE_FE_URL_XARDVARC as string;
export const bilbyBaseUrl = process.env.REACT_APP_BASE_FE_URL_BILBY as string;
export const lemmingBaseUrl = process.env
  .REACT_APP_BASE_FE_URL_LEMMING as string;
export const docsBaseUrl = process.env.REACT_APP_BASE_DOCS_URL as string;
export const supportBaseUrl = process.env.REACT_APP_BASE_SUPPORT_URL as string;

export const glossaryBaseUrl = process.env
  .REACT_APP_GLOSSARY_BASE_URL as string;

export const defaultLoginPath =
  process.env.REACT_APP_DEFAULT_LOGIN_PATH ?? "/login-test";

const logoutUrl = (input: {
  origin: string;
  authBaseUrl: string;
  idToken: string | null;
  clientId: string | null;
}) => {
  const { authBaseUrl, idToken, origin, clientId } = input;
  if (process.env.NODE_ENV !== "production") {
    return "/logged-out";
  }

  const redirectUri = `${origin}${appRouterBasename}/logged-out`;
  if (idToken && clientId) {
    return `${authBaseUrl}/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUri}&client_id=${clientId}`;
  } else {
    // fall back to not use post_logout_redirect_uri in the case that the ID token or clientId are missing
    return `${authBaseUrl}/logout`;
  }
};

export const Fennec = {
  REDIRECT_URI: (origin: string) => `${origin}${appRouterBasename}/oauth`,
  SCOPE: "openid profile email",
  CLIENT_ID: authClientId,
  AUTHORIZATION_ENDPOINT: `${authBaseUrl}/auth`,
  TOKEN_ENDPOINT: `${authBaseUrl}/token`,
  LOGOUT_URL: logoutUrl
};
