import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useSDK } from "../shared/useSDK";
import {
  Breadcrumb,
  BreadcrumbAligner,
  BreadcrumbLink
} from "@fennec/ui-common-cra/dist/components/lib/Breadcrumb";
import { useContent } from "@fennec/ui-glossary";

const listLink = {
  to: "/projects",
  text: "Projects"
};

export function BreadcrumbProject() {
  return (
    <BreadcrumbAligner>
      <Breadcrumb>
        <BreadcrumbLink to={listLink.to}>
          <span>{listLink.text}</span>
        </BreadcrumbLink>
        <BreadcrumbItem />
      </Breadcrumb>
    </BreadcrumbAligner>
  );
}

function BreadcrumbItem() {
  const { sdk } = useSDK();
  const is = useContent("410294F2-9CB2-49A5-917E-499801E93DDA");
  const params = useParams<{ uuid: string }>() as { uuid: string };
  const uuid = params.uuid;
  const result = useQuery(["project", uuid], () =>
    sdk.getProject({ projectUuid: uuid })
  );
  const to = `/projects/${params.uuid}`;
  const fallbackText = is("Project");
  if (result.status === "loading") {
    return null;
  }
  if (result.status === "error" || result.data === undefined) {
    return <BreadcrumbLink to={to}>{fallbackText}</BreadcrumbLink>;
  }
  return <BreadcrumbLink to={to}>{result.data.data.name}</BreadcrumbLink>;
}
