import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useSDK } from "../shared/useSDK";
import {
  Breadcrumb,
  BreadcrumbAligner,
  BreadcrumbLink
} from "@fennec/ui-common-cra/dist/components/lib/Breadcrumb";

// const listLink = {
//   to: "/organizations",
//   text: "Organizations",
// };

export function BreadcrumbOrganization() {
  return (
    <BreadcrumbAligner>
      <Breadcrumb>
        {/* <BreadcrumbLink to={listLink.to}>
          <span>{listLink.text}</span>
        </BreadcrumbLink> */}
        <BreadcrumbItem />
      </Breadcrumb>
    </BreadcrumbAligner>
  );
}

function BreadcrumbItem() {
  const { sdk } = useSDK();
  const params = useParams<{ uuid: string }>() as { uuid: string };
  const uuid = params.uuid;
  const result = useQuery(["organization", uuid], () =>
    sdk.getOrganization({ organizationUuid: uuid })
  );
  const to = `/organizations/${params.uuid}`;
  const fallbackText = "Organization";
  if (result.status === "loading") {
    return null;
  }
  if (result.status === "error" || result.data === undefined) {
    return <BreadcrumbLink to={to}>{fallbackText}</BreadcrumbLink>;
  }
  return <BreadcrumbLink to={to}>{result.data.data.name}</BreadcrumbLink>;
}
