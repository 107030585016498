import { Header } from "./Header";
import styles from "./Layout.module.css";
import { WithSidebar } from "./Sidebar";

export function Layout(props: { tabs?: any; header?: any; children: any }) {
  return (
    <div className={styles.layout}>
      <div>
        <Header />
      </div>
      <div>
        <WithSidebar>
          <div>
            {props.tabs || props.header ? (
              <div className={styles.top} data-with-border={!!props.tabs}>
                <div className={styles.limit}>
                  {props.header}
                  {props.tabs}
                </div>
              </div>
            ) : null}
            <main className={styles.main}>
              <div className={styles.limit}>{props.children}</div>
            </main>
          </div>
        </WithSidebar>
      </div>
    </div>
  );
}
