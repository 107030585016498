import jwtDecode from "jwt-decode";

export enum LocalStorageKey {
  API_TOKEN = "API_TOKEN",
  ID_TOKEN = "ID_TOKEN",
  LOGIN_CLIENT_ID = "LOGIN_CLIENT_ID"
}

export function isAuthenticated() {
  const token = window.localStorage.getItem(LocalStorageKey.API_TOKEN);
  if (token == null) {
    return false;
  }
  try {
    const decoded: any = jwtDecode(token);
    if (!decoded || decoded.exp == null) {
      return false;
    }
    return Date.now() > decoded.exp * 1000 ? false : true;
  } catch (e) {
    return false;
  }
}

// API_TOKEN

export function getToken() {
  return window.localStorage.getItem(LocalStorageKey.API_TOKEN);
}

export function setToken(value: string) {
  window.localStorage.setItem(LocalStorageKey.API_TOKEN, value);
}

export function removeToken() {
  return window.localStorage.removeItem(LocalStorageKey.API_TOKEN);
}

// ID_TOKEN

export function getIdToken() {
  return window.localStorage.getItem(LocalStorageKey.ID_TOKEN);
}

export function setIdToken(value: string) {
  window.localStorage.setItem(LocalStorageKey.ID_TOKEN, value);
}

export function removeIdToken() {
  return window.localStorage.removeItem(LocalStorageKey.ID_TOKEN);
}

// LOGIN_CLIENT_ID

export function getLoginClientId() {
  return window.localStorage.getItem(LocalStorageKey.LOGIN_CLIENT_ID);
}

export function setLoginClientId(value: string) {
  window.localStorage.setItem(LocalStorageKey.LOGIN_CLIENT_ID, value);
}

export function removeLoginClientId() {
  return window.localStorage.removeItem(LocalStorageKey.LOGIN_CLIENT_ID);
}
