import * as React from "react";

export const AuthContext = React.createContext<{
  token: string | null;
  signIn: (
    credentials: { accessToken: string; idToken: string },
    cb?: () => void
  ) => void;
  signOut: (authBaseUrl: string) => void;
}>(
  // @ts-ignore
  undefined
);
